<template>
  <div class="commodity">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/commodity')">首页</span> >
        <span class="">商品列表</span>
      </p>
    </div>
    <div class="search">
      <div class="c-box">
        <div style="float: left">
          <div class="box" style="margin-left: 58px">
            <div class="span">S&nbsp;K&nbsp;U&nbsp;</div>
            <el-input
              v-model="searchData.sku"
              class="input"
              placeholder="请输入"
            />
          </div>

          <div class="box" style="margin-top: 10px">
            <div class="span">商品名称(中文)&nbsp;</div>
            <el-input
              v-model="searchData.nameCn"
              placeholder="请输入"
            />
          </div>
        </div>
        <div style="float: left">
          <div class="box" style="margin-left: 78px">
            <div class="span">状态&nbsp;</div>
            <el-select v-model="searchData.status" placeholder="请选择">
              <el-option label="待审核" value="0" />
              <el-option label="审核通过" value="1" />
              <el-option label="审核不通过" value="-1" />
            </el-select>
          </div>
          <div class="box" style="margin-left: 50px; margin-top: 10px">
            <div class="span">海关编码&nbsp;</div>
            <el-input
              v-model="searchData.hsCode"
              placeholder="请输入"
            />
          </div>
        </div>
        <div style="float: left">
          <div class="box" style="margin-left: 78px">
            <div class="span">品牌&nbsp;</div>
            <el-input
              v-model="searchData.brand"
              placeholder="请输入"
            />
          </div>
          <div class="box" style="margin-left: 50px; margin-top: 10px">
            <div class="span">规格型号&nbsp;</div>
            <el-input
              v-model="searchData.specification"
              placeholder="请输入"
            />
          </div>
        </div>

        <el-button
          type="primary"
          round
          icon="el-icon-search"
          style="margin-left: 100px"
          @click="initSearch"
        >查询</el-button>
        <!-- <el-button type="success" round icon="el-icon-refresh-left" @click="resets">重置</el-button> -->
        <el-button
          style="margin-left: 100px; margin-top: 10px"
          type="success"
          round
          @click="goto('/commodity/addCommodity')"
        >新建商品</el-button>
      </div>
    </div>
    <div class="c-box">
      <div class="content">
        <el-table
          class="freightRate-table"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column show-overflow-tooltip type="selection" width="55" />
          <el-table-column show-overflow-tooltip prop="sku" label="SKU" />
          <el-table-column
            show-overflow-tooltip
            prop="nameCn"
            label="商品名称(中文)"
          />
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <img
                :src="scope.row.imageUrl"
                style="width: 25px; height: 25px; vertical-align: bottom"
              >
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="statusName" label="状态" />
          <el-table-column show-overflow-tooltip prop="remark" label="备注" />
          <el-table-column
            show-overflow-tooltip
            prop="typesName"
            label="商品类型"
          />
          <el-table-column show-overflow-tooltip prop="hsCode" label="海关编码" />
          <el-table-column
            show-overflow-tooltip
            prop="materialQuality"
            label="材质"
          />
          <el-table-column show-overflow-tooltip prop="brand" label="品牌" />
          <el-table-column
            show-overflow-tooltip
            prop="specification"
            label="规格型号"
          />
          <el-table-column
            show-overflow-tooltip
            prop="declaredValue"
            label="申报价值"
          />
          <el-table-column label="状态" align="center" width="120">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isValid"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                @change="Switch(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column width="60px" label="操作">
            <template slot-scope="scope">
              <i
                class="el-icon-edit-outline"
                style="cursor: pointer"
                @click="edit(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fenye"
          background
          layout="prev, pager, next"
          :current-page="searchData.pageNum"
          :page-size="searchData.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { goodsByPage, stopOrEnabled } from '@/api/goods.js';
export default {
  name: 'Commodity',
  props: {},
  data: function() {
    return {
      searchData: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      hsCode: 0,
      brand: '',
      specification: '',
      tableData: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.goodsByPage();
  },
  mounted() {},
  methods: {
    goodsByPage() {
      // 分页商品列表
      goodsByPage(this.searchData)
        .then((result) => {
          if (result.status === 200) {
            const data = result.data.data;
            this.tableData = data.list;
            this.total = data.total;
            this.searchData.pageSize = data.pageSize;
            this.searchData.pageNum = data.pageNum;
            this.hsCode = data.hsCode;
            this.brand = data.brand;
            this.specification = data.specification;
            console.log(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initSearch() {
      // 搜索
      this.searchData.pageNum = 1;
      this.goodsByPage();
    },
    resets() {
      // 重置
      this.searchData = { pageNum: 1, pageSize: 10 };
      this.goodsByPage();
    },
    handleCurrentChange(val) {
      // 切换页数
      this.searchData.pageNum = val;
      this.goodsByPage();
    },
    edit(row) {
      this.$store.commit('newCommodity', row);
      this.$router.push({ path: '/commodity/editCommodity' });
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    Switch(val) {
      stopOrEnabled({ id: val.id, isValid: val.isValid }).then((res) => {
        if (+res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.title {
  height: 30px;
  line-height: 30px;
  background: #f0f0f0;
  margin: 0;
  font-size: 12px;
  color: #999999;
}
.title i {
  margin-right: 10px;
}
.title span {
  cursor: pointer;
}
.title span:nth-child(3) {
  color: #333;
}
.button {
  min-width: 80px;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
}
.label {
  font-size: 12px;
  display: inline-block;
  margin-right: 20px;
}
.select {
  background: #fc6c3f;
  color: #fff;
  border-color: #fc6c3f !important;
}
.transport {
  overflow: hidden;
  margin-top: 44px;
}
.transport > div {
  width: 50%;
  float: left;
}
.search {
  margin: 20px auto;
}
.span {
  float: left;
  margin-top: 7px;
}

.el-input,
.el-select,
.el-date-editor {
  width: 200px;
}
</style>
