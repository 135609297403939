import require from '@/utils/require';
import { network, auto } from '@/config/geteway';

// 退出
export const sigin = function(params) {
  return require({
    url: `${network}/${auto}/signout`,
    method: 'GET',
    params
  });
};
// 商品列表
export const goodsByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/findCustomerGoodsByPage`,
    method: 'POST',
    data
  });
};
// 添加编辑保存商品
export const saveGood = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/saveCustomerGoods`,
    method: 'POST',
    data
  });
};
// 下载
export const down = `${network}/${auto}/customergoods/downloadExcelTemplateByCustomerGoods`;
// 上传文件
export const importExcel = function(data = {}) {
  return require({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: `${network}/${auto}/customergoods/importExcelByCustomerGoods`,
    method: 'POST',
    data
  });
};
// 确认商品
export const confirmGood = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/batchSaveCustomerGoods`,
    method: 'POST',
    data
  });
};
// 地址list
export const findDeliveryAddressByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/deliveryaddress/findDeliveryAddressByPage`,
    method: 'POST',
    data
  });
};
// 添加地址
export const saveDeliveryAddress = function(data = {}) {
  return require({
    url: `${network}/${auto}/deliveryaddress/saveDeliveryAddress`,
    method: 'POST',
    data
  });
};
// 客户商品启用停用
export const stopOrEnabled = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/stopOrEnabled`,
    method: 'POST',
    data
  });
};
