// import { Message } from "element-ui";
import axios from 'axios';
function require(option) {
  return new Promise((fulfill, reject) => {
    axios(option).then((res) => {
      fulfill(res);
    }).catch((rej) => {
      if (rej.response) {
        reject(rej.response);
      } else if (rej.require) {
        reject(rej.require);
      } else {
        reject(rej.message);
      }
    });
  });
}
export default require;
